
$border-color:#f3f0eb;

@mixin item-box-shadow {
	-webkit-box-shadow: 2px 2px 8px 2px rgba(0,0,0,0.18);
	-moz-box-shadow: 2px 2px 8px 2px rgba(0,0,0,0.18);
	box-shadow: 2px 2px 8px 2px rgba(0,0,0,0.18);
}

.col-xs-1-5, .col-sm-1-5, .col-md-1-5, .col-lg-1-5,
.col-xs-2-5, .col-sm-2-5, .col-md-2-5, .col-lg-2-5,
.col-xs-3-5, .col-sm-3-5, .col-md-3-5, .col-lg-3-5,
.col-xs-4-5, .col-sm-4-5, .col-md-4-5, .col-lg-4-5 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}	
.col-xs-1-5 {
	width: 20%;
	float: left;
}
.col-xs-2-5 {
	width: 40%;
	float: left;
}
	
.col-xs-3-5 {
	width: 60%;
	float: left;
}
	
.col-xs-4-5 {
	width: 80%;
	float: left;
}
@media (min-width: 768px) {
	.col-sm-1-5 {
		width: 20%;
		float: left;
	}
	col-sm-2-5 {
		width: 40%;
		float: left;
	}
	col-sm-3-5 {
		width: 60%;
		float: left;
	}
	col-sm-4-5 {
		width: 80%;
		float: left;
	}
}
@media (min-width: 992px) {
	.col-md-1-5 {
		width: 20%;
		float: left;
	}
	.col-md-2-5 {
		width: 40%;
		float: left;
	}
	.col-md-3-5 {
		width: 60%;
		float: left;
	}
	.col-md-4-5 {
		width: 80%;
		float: left;
	}
}
@media (min-width: 1200px) {
	.col-lg-1-5 {
		width: 20%;
		float: left;
	}
	.col-lg-2-5 {
		width: 40%;
		float: left;
	}
	.col-lg-3-5 {
		width: 60%;
		float: left;
	}
	.col-lg-4-5 {
		width: 80%;
		float: left;
	}
}

.no-padding {
	padding: 0 !important;
}

/** 自訂多重下拉bar */

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}
/** END */

body {
	background:$border-color;
}
#top-nav {
	border-radius: 0;
	background-color: #000;
	margin-bottom: 0;
	font-weight: bolder;
	.nav>li {
		padding-left: 6px;
		padding-right: 6px;
	}
	.active{
		a:focus {
			background-color: #000 !important;
		}
	}
	.dropdown-menu {
		background-color: rgba(0,0,0,0.75);
		a {
			color: #fff;
		}
		li {
			a:hover {
				background-color: rgba(0,0,0,1);
				color: #fff;
			}
		}
	}
	.dropdown:hover>.dropdown-menu {
	    display: block;
	    margin-top: 0; // remove the gap so it doesn't close
	 }
}

.product-box {
	// border:$border-color 1px solid;
	@include item-box-shadow;
	// -webkit-box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.18);
	// -moz-box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.18);
	// box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.18);

	.box-top{
		border-bottom: $border-color 1px solid;
		// padding: 8px;
	}
	.box-bottom {
		// height: 120px;
		padding-top: 30px;
		padding-bottom: 20px;
		// padding-left: 25px;
		overflow: hidden;
		background-color: #fff;
		.box-left {
			// float: left;
			// width: 70%;
			padding-right: 10px;
			// padding-left: 30px;
		}
		.box-right {
			// float: right;
			text-align: right;
			// padding-right: 30px;
			// width: 30%;
		}
		.name {
			font-size: 15px;
			height: 36px;
			line-height: 18px;
			overflow: hidden;
		}
		.price {
			margin-top: 13px;
			font-weight: bolder;
			font-size: 12px;
			color: #cc0000;
		}
		.sprice {
			color: #cc0000;
			font-weight: bolder;
			letter-spacing: -1px;
			font-size: 28px;
			line-height: 66px;
		}
	}
	&.advice {
		.box-top {
			background: url(../img/advice.gif) right 0 no-repeat;	
		}
	}
	&.favourable {
		.box-top {
			background: url(../img/favourable.gif) right 0 no-repeat;	
		}
	}
}

.divider {
	height: 30px;
	width: 100%;
}

.item-box  {
	// border:$border-color 1px solid;
	margin-bottom: 30px;
	@include item-box-shadow;
	.box-top{
		border-bottom: $border-color 1px solid;
	}
	.box-bottom {
		height: 75px;
		padding-top: 18px;
		// padding-bottom: 20px;
		// padding-left: 25px;
		background-color: #fff;
		overflow: hidden;
		.box-left {
			// padding-left: 30px;
			color: #cc0000;
			font-size: 16px;
			font-weight: bold;
			a {
				color: #cc0000;
			}
		}
		.box-right {
			font-size: 30px;
			a {
				color: #000000;
			}
		}
	}
}

.social {
	margin-top: 20px;
	.fb-banner {
		width: 100%;
		height: 80px;
		background: url(../img/fb-banner.gif) center center no-repeat;
		@include item-box-shadow;
	}
	.line-banner {
		width: 100%;
		height: 80px;
		background: url(../img/line-banner.gif) center center no-repeat;
		@include item-box-shadow;
	}
}

.new-arrivals {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.product-box {
		.box-bottom {
			padding-bottom: 30px;
			.sprice {
				// line-height: 66px;
			}
		}
		&.box-s {
			.box-top{
				border-bottom: #ccc 1px solid;
				padding: 0;
				img {
					height: 140px;
				}
			}
			.box-bottom {
				padding-top: 20px;
				padding-bottom: 20px;
			}
			.name {
				height: 55px;
			}
			.sprice {
				font-size: 22px;
				line-height: 80px;
			}
		}
	}
}
.surprise {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.product-box {
		.box-bottom {
			// height: 120px;
			padding-top: 10px;
			padding-bottom: 10px;
		}	
	}
	.more {
		height: 355px;
		background:#c6010b url(../img/more.gif) center 30px no-repeat;
	}
	
}
.bonbonbuy {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.product-box {
		.box-bottom {
			padding-top: 21px;
			height: 102px;
			.sprice {
				// line-height: 66px;
			}
		}
		&.box-w {
			.box-bottom {
				padding-top: 10px;
				padding-bottom: 10px;
				height: auto;
				.name {
					height: 20px;
				}
				.price {
					margin-top: 8px;
				}
				.sprice {
					line-height: 44px;
				}
			}
		}
		&.box-s {
			.box-bottom {
				height: 86px;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
}
.hot-sale {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.product-box {
		.box-bottom {
			padding-bottom: 30px;
		}
		&.box-s {
			.box-top{
				border-bottom: #ccc 1px solid;
				padding: 0;
				img {
					
				}
			}
			.box-bottom {
				padding-top: 20px;
				padding-bottom: 20px;
			}
			.name {
				height: 55px;
			}
			.sprice {
				font-size: 22px;
				line-height: 80px;
			}
		}
	}
}

.review {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.item-box {
		.box-bottom {
			// height: 120px;
			padding-top: 15px;
			padding-bottom: 15px;
			height: auto;
			.box-left {
				a {
					color: #000;
				}
			}
			.box-right {
				a {
					color: #cc0000;
				}
			}
		}	
	}
}

.welfare {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.item-box {
		.box-bottom {
			padding-top: 5px;
			height: 65px;
			.box-left {
				.title {
					overflow: hidden;
					height: 36px;
					line-height: 18px;
				}
				p{
					color: #000;
					font-size: 13px;
					line-height: 15px;
				}
			}
		}
		&.box-w {
			.box-bottom {
				height: 70px;
				.box-left {
					.title {
						line-height: 36px;
					}
				}
			}
		}
	}
}
.pets-school {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.pets-school-selected {
		width: 50%;
		float: left;
	}
	.school-select {
		width: 50%;
		padding: 0 30px;
		float: right;
		height: 277.5px;
		text-align: center;
		background: #D7D3D0; /* Old browsers */
		.title {
			margin-top: 20px;
			margin-bottom: 10px;
			font-size: 18px;
			font-weight: bold;
			line-height: 30px;
		}
		select {
			outline: none;
			border: 0px none;
			line-height: 1.42857143;
			padding: 6px 12px;
			font-size: 14px;
			display: block;
			width: 100%;
			height: 34px;
			// background-color: transparent;
		}
	}
}
.charity {
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-top: 20px;
		margin-bottom: 15px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.item-box {
		.box-bottom {
			padding-top: 10px;
			padding-left: 10px;
			padding-right: 10px;
			height: 65px;
			font-size: 16px;
		}
	}
}

.shelf {
	.item {
		.box-top {
			.pic{
				img {
					@include item-box-shadow;
				}
			}
		}
	}
}

#brand-content {
	.explanation {
		.nav {
			li.active a{
				background-color: transparent;
			}
		}
	}	
}
#product-content {
	.detail {
		.nav {
			li.active a{
				background-color: transparent;
				border-bottom: 0 none;
			}
		}
	}
}

@media (max-width: 768px) {
	.pets-school {
		.pets-school-selected {
			width: 100%;
			float: none;
		}
		.school-select {
			width: 100%;
			float: none;
		}
	}
}